import React, {useState, useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {Redirect} from 'react-router-dom'
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend'
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import axios from 'axios';
import {getToken, removeUserSession, setUserSession} from './Utils/Common';
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import 'semantic-ui-css/semantic.min.css'

import Page from './page/Page'
import Editor from './editor/Editor'
import EditorPreview from './preview/Editor'
import Modal from './modal/Modal'

import CmsHelper from './helpers/Cms'

function App() {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if(!token) {
      return;
    }

    axios.post('https://babor.fp-eauthor.fp-server.com/cms/api/collections/get/tokens?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
      filter: {
        token: token
      }
    }).then(response => {
      if(response.data.entries.length > 0) {
        axios.post('https://babor.fp-eauthor.fp-server.com/cms/api/collections/get/users?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
          filter: {
            mail: response.data.entries[0].mail
          }
        }).then(response2 => {
          if(response2.data.entries.length > 0) {
            setUserSession(token, response2.data.entries[0]._id, response2.data.entries[0].mail, response2.data.entries[0].first_name, response2.data.entries[0].last_name, response2.data.entries[0].image.path);
            setAuthLoading(false);
          } else {
            removeUserSession();
            setAuthLoading(false);
          }
        })
      } else {
        removeUserSession();
        setAuthLoading(false);
      }
    })
  }, []);

  if(authLoading && getToken()) {
    return null
  }

  return (
    <AppInner />
  );
}

class AppInner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: '',
      modalShow: false,
      modalData: {}
    }
  }

  async duplicateWBT(id) {
    await CmsHelper.duplicateWBT(id)
    window.location.reload()
  }

  async deleteWBT(id) {
    await CmsHelper.deleteWBT(id)
    window.location.reload()
  }

  async useTemplate(id) {
    this.setState({
      modal: 'use_template',
      modalShow: true,
      modalData: {
        templateId: id
      }
    })
  }

  handleDuplicateWBT(id) {
    this.duplicateWBT(id)
  }

  handleUseTemplate(id) {
    this.useTemplate(id)
  }

  handleDeleteWBT(id) {
    this.deleteWBT(id)
  }

  handleOpenModal(modal) {
    if(modal.includes('external_link_')) {
      var split = modal.split('external_link_');
      var id = split[1]
      this.setState({
        modal: 'external_link',
        modalShow: true,
        modalData: {
          id: id
        }
      })
    } else {
      this.setState({
        modal: modal,
        modalShow: true
      })
    }
  }

  handleCloseModal() {
    this.setState({
      modal: '',
      modalShow: false
    })
  }

  handleOpenHelpVideo(video) {
    this.setState({
      modal: 'help_video',
      modalShow: true,
      modalData: {
        video: video
      }
    })
  }

  render() {
    var props = {
      onDuplicateWBT: this.handleDuplicateWBT.bind(this),
      onDeleteWBT: this.handleDeleteWBT.bind(this),
      onUseTemplate: this.handleUseTemplate.bind(this),
      onOpenModal: this.handleOpenModal.bind(this),
      onCloseModal: this.handleCloseModal.bind(this),
      onOpenHelpVideo: this.handleOpenHelpVideo.bind(this)
    }
    return(
      <>
        <Router>
          <Switch>
            <PublicRoute
              path="/login"
              component={(routeProps) => (
                <Page
                  {...routeProps}
                  {...props}
                  page="login"
                />
              )}
            />
            <PrivateRoute
              path="/wbts"
              component={(routeProps) => (
                <Page
                  {...props}
                  page="wbts"
                />
              )}
            />
            <PrivateRoute
              path="/editor/:wbtId/:action?/:customId?"
              component={(routeProps) => (
                <DndProvider backend={HTML5Backend}>
                  <Editor
                    {...routeProps}
                    {...props}
                  />
                </DndProvider>
              )}
            />
            <Route
              path="/preview/:wbtId/:action?/:customId?"
              component={(routeProps) => (
                <DndProvider backend={HTML5Backend}>
                  <EditorPreview
                    {...routeProps}
                    {...props}
                  />
                </DndProvider>
              )}
            />
            <PrivateRoute
              path="/vorlagen"
              component={(routeProps) => (
                <Page
                  {...props}
                  page="templates"
                />
              )}
            />
            <PrivateRoute
              path="/mediathek"
              component={(routeProps) => (
                <Page
                  {...props}
                  page="media_library"
                />
              )}
            />
            <PrivateRoute
              path="/hilfe"
              component={(routeProps) => (
                <Page
                  {...props}
                  page="help"
                />
              )}
            />
            <PrivateRoute
              path="/profil"
              component={(routeProps) => (
                <Page
                  {...props}
                  page="profile"
                />
              )}
            />
            <PrivateRoute
              path="/"
              component={(routeProps) => (
                <Page
                  {...props}
                  page="dashboard"
                />
              )}
            />
          </Switch>
          <Modal
            modal={this.state.modal}
            modalShow={this.state.modalShow}
            modalData={this.state.modalData}
            onCloseModal={this.handleCloseModal.bind(this)}
          />
        </Router>
      </>
    )
  }
}

export default App
